/* BlubStaking.css - Style for the BLUB staking page */

/* Import base styles */
@import './Home.css';

/* Back link styling - updated for better visibility */
.back-link {
  display: inline-block;
  margin-bottom: 30px;
  text-decoration: none;
  position: relative;
}

.back-button {
  background: rgba(0, 20, 40, 0.8);
  padding: 12px 24px;
  border-radius: 30px;
  border: 2px solid rgba(0, 255, 255, 0.4);
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.3);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-button .fa {
  font-size: 18px;
  color: #00ffff;
  letter-spacing: 1px;
  text-shadow: 0 0 8px rgba(0, 255, 255, 0.5);
}

.back-link:hover .back-button {
  background: rgba(0, 40, 60, 0.9);
  border-color: rgba(255, 0, 255, 0.5);
  box-shadow: 0 0 20px rgba(255, 0, 255, 0.4);
  transform: translateY(-3px);
}

.back-link:hover .fa {
  color: #ff00ff;
  text-shadow: 0 0 8px rgba(255, 0, 255, 0.7);
}

/* Add keyframes for text glitch effect */
@keyframes textGlitch {
  0% {
    text-shadow: -1px -1px 0 rgba(0, 255, 255, 0.7), 1px 1px 0 rgba(255, 0, 255, 0.7);
    transform: translate(0);
  }
  20% {
    text-shadow: 1px 1px 0 rgba(0, 255, 255, 0.7), -1px -1px 0 rgba(255, 0, 255, 0.7);
    transform: translate(-2px, 2px);
  }
  40% {
    text-shadow: 1px -1px 0 rgba(0, 255, 255, 0.7), -1px 1px 0 rgba(255, 0, 255, 0.7);
    transform: translate(2px, 2px);
  }
  60% {
    text-shadow: -1px 1px 0 rgba(0, 255, 255, 0.7), 1px -1px 0 rgba(255, 0, 255, 0.7);
    transform: translate(0);
  }
  80% {
    text-shadow: -1px -1px 0 rgba(0, 255, 255, 0.7), 1px 1px 0 rgba(255, 0, 255, 0.7);
    transform: translate(1px, -1px);
  }
  100% {
    text-shadow: 1px 1px 0 rgba(0, 255, 255, 0.7), -1px -1px 0 rgba(255, 0, 255, 0.7);
    transform: translate(0);
  }
}

@keyframes textFlicker {
  0% { opacity: 1; }
  10% { opacity: 0.8; }
  20% { opacity: 1; }
  40% { opacity: 0.9; }
  60% { opacity: 1; }
  70% { opacity: 0.7; }
  80% { opacity: 1; }
  90% { opacity: 0.9; }
  100% { opacity: 1; }
}

/* Staking specific container */
.staking-container {
  max-width: 1200px;
  width: 95%;
  margin: 0 auto;
  padding: 40px 0;
  position: relative;
  z-index: var(--z-index-content);
}

/* Change background to bg22.gif */
.cyberpunk-bg {
  background-image: url('../styles/images/bg22.gif') !important;
  background-position: center center;
  background-size: cover;
  position: relative;
  min-height: 100vh;
}

/* Staking header styling */
.staking-header {
  margin-bottom: 40px;
  text-align: center;
}

.staking-title {
  font-size: 56px;
  margin: 0;
  letter-spacing: 3px;
  line-height: 1.2;
}

/* Gradient text styling */
.gradient-text-0 {
  background: linear-gradient(to right, #00ffff, #008888);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  text-shadow: none;
}

.gradient-text-3 {
  background: linear-gradient(to right, #ff00ff, #880088);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  text-shadow: none;
}

/* Main content area */
.staking-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

/* Card styling */
.staking-card {
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(0, 255, 255, 0.3);
  border-radius: 16px;
  overflow: hidden;
  backdrop-filter: blur(8px);
  margin-bottom: 30px;
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.15);
  animation: float 6s ease-in-out infinite;
  transition: all 0.3s ease;
}

.staking-card:hover {
  box-shadow: 0 0 30px rgba(0, 255, 255, 0.3);
  border-color: rgba(0, 255, 255, 0.5);
  transform: translateY(-5px);
}

.card-header {
  background: linear-gradient(90deg, rgba(0, 20, 40, 0.9), rgba(20, 0, 40, 0.9));
  padding: 20px;
  border-bottom: 1px solid rgba(0, 255, 255, 0.3);
  text-align: center;
}

.card-header h2 {
  margin: 0;
  letter-spacing: 2px;
  font-size: 24px;
}

/* Staking body content */
.staking-body {
  padding: 30px;
}

/* Balance grid layout */
.balance-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 40px;
}

/* Updated balance cell styling */
.balance-cell {
  background: rgba(0, 20, 40, 0.7);
  padding: 25px 15px;
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 255, 0.3);
  text-align: center;
  transition: all 0.3s ease;
  backdrop-filter: blur(4px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.balance-cell:hover {
  background: rgba(0, 30, 50, 0.8);
  border-color: rgba(0, 255, 255, 0.6);
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 255, 255, 0.2);
}

.balance-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.balance-label {
  font-size: 20px;
  color: #00ffff;
  margin: 0;
  text-shadow: 0 0 8px rgba(0, 255, 255, 0.5);
  letter-spacing: 1px;
}

.balance-label-subtitle {
  font-size: 16px;
  color: #00cccc;
  margin-top: -5px;
  margin-bottom: 10px;
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.3);
  letter-spacing: 1px;
}

.balance-value {
  font-size: 28px;
  font-weight: bold;
  color: white;
  margin-top: 5px;
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
}

.glitch-value {
  position: relative;
  display: inline-block;
}

.glitch-value:hover {
  animation: textFlicker 4s infinite;
}

/* Connect wallet message and button */
.connect-wallet-message {
  padding: 50px 20px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  margin: 20px;
}

.connect-wallet-message p {
  font-size: 20px;
  letter-spacing: 1.5px;
  color: var(--primary-color);
  margin-bottom: 30px;
  line-height: 1.5;
}

.connect-wallet-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.ccbutton {
  border-radius: 12px !important;
  overflow: hidden;
  transition: all 0.3s ease !important;
}

.ccbutton:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 255, 255, 0.2);
}

/* Action panel for staking forms */
.action-panel {
  background: rgba(0, 0, 0, 0.3);
  padding: 30px;
  border-radius: 16px;
  border: 1px solid rgba(0, 255, 255, 0.15);
  backdrop-filter: blur(4px);
}

.form-group {
  margin-bottom: 25px;
}

.form-group:last-child {
  margin-bottom: 0;
}

.cyberpunk-label {
  display: block;
  margin-bottom: 10px;
  color: var(--primary-color);
  letter-spacing: 1px;
  text-transform: uppercase;
}

.cyber-input-container {
  display: flex;
  gap: 15px;
  align-items: center;
}

.cyber-input {
  flex: 1;
  background: rgba(0, 20, 40, 0.7);
  border: 1px solid rgba(0, 255, 255, 0.3);
  color: white;
  padding: 15px 20px;
  border-radius: 12px;
  font-family: 'Doctor Glitch', sans-serif;
  font-size: 18px;
  transition: all 0.3s ease;
}

.cyber-input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.2);
  background: rgba(0, 20, 40, 0.8);
}

/* Custom button styling with border radius */
.custom-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  border: none;
  background-size: cover;
  background-position: center;
  min-width: 200px;
  height: 50px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  border-radius: 12px;
  overflow: hidden;
}

.custom-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none !important;
}

.button-text {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
}

/* Center aligned form group */
.form-group.centered {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.claim-button {
  min-width: 250px;
  height: 60px;
  border-radius: 12px;
}

/* Error message styling */
.error-message {
  color: red;
  margin: 10px 0;
  text-align: center;
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.2);
  padding: 10px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.glitch-text-error {
  animation: textGlitch 0.3s ease-in-out infinite;
}

/* How it works section */
.how-it-works {
  margin-top: 60px;
  width: 100%;
}

.section-title {
  font-size: 28px;
  margin-bottom: 25px;
  text-align: center;
  color: var(--primary-color);
  position: relative;
  letter-spacing: 2px;
}

.steps-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 800px;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.4);
  padding: 30px;
  border-radius: 16px;
  border: 1px solid rgba(0, 255, 255, 0.2);
  backdrop-filter: blur(4px);
}

.step {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 15px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.step:hover {
  transform: translateX(5px);
}

.step:last-child {
  border-bottom: none;
}

.step-number {
  background: var(--primary-color);
  color: black;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  font-size: 18px;
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.3);
}

.step-text {
  font-size: 16px;
  line-height: 1.5;
  color: white;
  position: relative;
}

/* Transaction status overlay */
.transaction-status-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(8px);
}

.transaction-status-modal {
  background: rgba(20, 20, 40, 0.95);
  border: 1px solid var(--primary-color);
  padding: 40px;
  border-radius: 16px;
  text-align: center;
  max-width: 450px;
  width: 90%;
  box-shadow: 0 0 30px rgba(0, 255, 255, 0.3);
  animation: float 4s ease-in-out infinite;
}

.transaction-spinner {
  width: 60px;
  height: 60px;
  border: 4px solid rgba(0, 255, 255, 0.3);
  border-top: 4px solid var(--primary-color);
  border-radius: 50%;
  margin: 0 auto 25px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.transaction-status-text {
  font-size: 20px;
  margin-bottom: 20px;
  color: white;
  font-family: 'Doctor Glitch', sans-serif;
  letter-spacing: 1.5px;
}

.transaction-note {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.5;
}

/* Scanline effect for cyberpunk style */
.scanline-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.15),
    rgba(0, 0, 0, 0.15) 1px,
    transparent 1px,
    transparent 2px
  );
  pointer-events: none;
  z-index: 2;
}

/* Responsive styles */
@media (max-width: 768px) {
  .staking-container {
    padding: 20px 0;
  }

  .staking-title {
    font-size: 40px;
    letter-spacing: 2px;
  }
  
  .balance-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .balance-label {
    font-size: 18px;
  }

  .balance-label-subtitle {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .balance-value {
    font-size: 24px;
  }
  
  .cyber-input-container {
    flex-direction: column;
  }
  
  .custom-button {
    width: 100%;
    min-width: unset;
  }
  
  .staking-body {
    padding: 20px;
  }

  .action-panel {
    padding: 20px;
  }

  .step {
    padding: 10px 0;
  }

  .step-text {
    font-size: 16px;
  }

  .back-button {
    padding: 10px 20px;
  }

  .back-button .fa {
    font-size: 16px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .staking-container {
    padding: 30px 0;
  }

  .staking-title {
    font-size: 48px;
  }

  .balance-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  .balance-cell:nth-child(3) {
    grid-column: span 2;
    max-width: 50%;
    margin: 0 auto;
  }
  
  .custom-button {
    min-width: 180px;
  }

  .step-text {
    font-size: 16px;
  }
}

/* Custom button hover matching main app */
.custom-button:hover .fa {
  animation: textFlicker 4s infinite;
  color: white !important;
}

.staking-title.fa {
  animation: textFlicker 8s infinite;
}

/* Add claim-rewards-button animations from App.tsx */
.claim-button {
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease !important;
  border: 2px solid #ff69b4 !important;
  background-color: rgba(0, 20, 40, 0.7) !important;
  backdrop-filter: blur(4px);
}

.claim-button:hover {
  animation: buttonPulse 1.5s infinite, pinkGlow 2s infinite !important;
  transform: translateY(-3px) !important;
  filter: contrast(1.2);
  background-color: rgba(0, 30, 60, 0.8) !important;
}

.claim-button:hover .fa {
  animation: glitchText 2s infinite;
  color: white !important;
}

.claim-button:active {
  animation: buttonClick 0.2s ease !important;
  transform: scale(0.95) !important;
}

/* Add these keyframes from App.tsx for consistency */
@keyframes pinkGlow {
  0% { box-shadow: 0 0 5px #ff69b4, 0 0 10px #ff1493; }
  50% { box-shadow: 0 0 15px #ff69b4, 0 0 25px #ff1493, 0 0 35px rgba(255, 105, 180, 0.5); }
  100% { box-shadow: 0 0 5px #ff69b4, 0 0 10px #ff1493; }
}

@keyframes glitchText {
  0% { text-shadow: 0.05em 0 0 #ff00ff, -0.05em -0.025em 0 #00ffff; }
  25% { text-shadow: -0.05em -0.025em 0 #ff00ff, 0.025em 0.05em 0 #00ffff; }
  50% { text-shadow: 0.025em 0.05em 0 #ff00ff, -0.05em -0.025em 0 #00ffff; }
  75% { text-shadow: -0.05em -0.025em 0 #ff00ff, 0.025em 0.05em 0 #00ffff; }
  100% { text-shadow: 0.05em 0 0 #ff00ff, -0.05em -0.025em 0 #00ffff; }
}

@keyframes buttonPulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.05); opacity: 0.9; }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes buttonClick {
  0% { transform: scale(1); }
  50% { transform: scale(0.95); }
  100% { transform: scale(1); }
} 