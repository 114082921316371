/* Base styles */
:root {
  --primary-color: #00ffff;
  --secondary-color: #ff00ff;
  --accent-color: #008080;
  --background-dark: #070707;
  --text-color: #ffffff;
  --glow-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
  --card-bg: rgba(13, 13, 20, 0.7);
  --z-index-base: 1;
  --z-index-scanline: 2;
  --z-index-content: 10;
  --z-index-buttons: 100;
  --z-index-social: 1001;
  --z-index-video: 1002;
}

/* Font faces */
@font-face {
  font-family: 'Another Danger';
  src: url('../styles/fonts/Another Danger - Demo.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Doctor Glitch';
  src: url('../styles/fonts/Doctor Glitch.otf') format('opentype');
  font-weight: normal;
  font-style: oblique;
}

/* Add keyframes for glitch effects */
@keyframes glitch {
  0% {
    transform: translate(0);
    text-shadow: -2px -2px 0 #00ffff, 2px 2px 0 #ff00ff;
  }
  25% {
    transform: translate(-5px, 5px);
    text-shadow: 2px -2px 0 #ff00ff, -2px 2px 0 #00ffff;
  }
  50% {
    transform: translate(5px, -5px);
    text-shadow: -3px 3px 0 #00ffff, 3px -3px 0 #ff00ff;
  }
  75% {
    transform: translate(-5px, -5px);
    text-shadow: 3px 3px 0 #ff00ff, -3px -3px 0 #00ffff;
  }
  100% {
    transform: translate(0);
    text-shadow: -2px -2px 0 #00ffff, 2px 2px 0 #ff00ff;
  }
}

@keyframes scanline {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* Main container */
.main {
  min-height: 100vh;
  height: auto;
  padding-bottom: calc(5% + 120px);
  width: 100%;
  position: relative;
  background-image: url('../styles/images/new/NBGM.gif');
  background-position: top;
  background-size: cover;
  overflow: hidden;
  z-index: var(--z-index-base);
}

/* Scanline effect - fixed positioning */
.main::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.15),
    rgba(0, 0, 0, 0.15) 1px,
    transparent 1px,
    transparent 2px
  );
  pointer-events: none;
  animation: scanline 10s linear infinite;
  opacity: 0.3;
  z-index: var(--z-index-scanline);
}

/* NEW GLITCHY CYBERPUNK EXPLORE BUTTON ANIMATION */
@keyframes exploreButtonGlitch {
  0%, 100% {
    transform: translate(0);
    text-shadow: 0 0 0 transparent;
    box-shadow: 
      0 0 15px var(--primary-color),
      0 0 0 rgba(0, 255, 255, 0.3),
      0 0 0 rgba(255, 0, 255, 0.3);
    border: 2px solid var(--primary-color);
    background-position: center center;
  }
  10% {
    transform: translate(-3px, 2px);
    text-shadow: -2px 2px 0 var(--secondary-color);
    box-shadow: 
      5px -3px 15px var(--secondary-color),
      -3px 2px 15px var(--primary-color),
      0 0 4px var(--accent-color);
    border-left: 2px solid var(--secondary-color);
    border-right: 2px solid var(--primary-color);
    background-position: center top;
  }
  20% {
    transform: translate(3px, -2px);
    text-shadow: 2px -2px 0 var(--primary-color);
    box-shadow: 
      -5px 3px 15px var(--primary-color),
      3px -2px 15px var(--secondary-color),
      0 0 6px var(--accent-color);
    border-top: 2px solid var(--secondary-color);
    border-bottom: 2px solid var(--primary-color);
    background-position: right center;
  }
  30% {
    transform: translate(-2px, -2px);
    text-shadow: -2px -2px 0 var(--accent-color);
    box-shadow: 
      -2px -2px 10px var(--accent-color),
      2px 2px 15px var(--primary-color),
      0 0 5px var(--secondary-color);
    border-top: 2px solid var(--accent-color);
    border-left: 2px solid var(--primary-color);
    background-position: left center;
  }
  40% {
    transform: translate(2px, 2px);
    text-shadow: 2px 2px 0 var(--primary-color);
    box-shadow: 
      2px 2px 10px var(--primary-color),
      -2px -2px 15px var(--accent-color),
      0 0 7px var(--secondary-color);
    border-bottom: 2px solid var(--secondary-color);
    border-right: 2px solid var(--accent-color);
    background-position: center bottom;
  }
  50% {
    transform: translate(0);
    text-shadow: 0 0 10px var(--primary-color);
    box-shadow: 
      0 0 20px var(--primary-color),
      0 0 10px var(--secondary-color),
      0 0 5px var(--accent-color);
    border: 2px solid var(--primary-color);
    background-position: center center;
  }
  60% {
    transform: translate(3px, 0);
    text-shadow: 3px 0 0 var(--secondary-color);
    box-shadow: 
      3px 0 15px var(--secondary-color),
      -3px 0 15px var(--primary-color),
      0 0 8px var(--accent-color);
    border-top: 2px solid var(--primary-color);
    border-bottom: 2px solid var(--secondary-color);
    background-position: 45% 45%;
  }
  70% {
    transform: translate(-3px, 0);
    text-shadow: -3px 0 0 var(--accent-color);
    box-shadow: 
      -3px 0 15px var(--accent-color),
      3px 0 15px var(--secondary-color),
      0 0 9px var(--primary-color);
    border-left: 2px solid var(--accent-color);
    border-right: 2px solid var(--secondary-color);
    background-position: 55% 55%;
  }
  80% {
    transform: translate(0, 3px);
    text-shadow: 0 3px 0 var(--primary-color);
    box-shadow: 
      0 3px 15px var(--primary-color),
      0 -3px 15px var(--accent-color),
      0 0 10px var(--secondary-color);
    border-bottom: 2px solid var(--primary-color);
    border-top: 2px solid var(--accent-color);
    background-position: 48% 52%;
  }
  90% {
    transform: translate(0, -3px);
    text-shadow: 0 -3px 0 var(--secondary-color);
    box-shadow: 
      0 -3px 15px var(--secondary-color),
      0 3px 15px var(--primary-color),
      0 0 12px var(--accent-color);
    border-top: 2px solid var(--secondary-color);
    border-left: 2px solid var(--accent-color);
    background-position: 52% 48%;
  }
}

/* Background overlay */
.main::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 
    linear-gradient(135deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.4) 100%),
    repeating-linear-gradient(
      0deg,
      transparent,
      transparent 2px,
      rgba(0, 255, 255, 0.05) 3px,
      rgba(0, 255, 255, 0.05) 3px
    );
  pointer-events: none;
  z-index: 1;
}

/* Typography with glitch effect */
.head {
  font-size: 48px;
  margin: 1rem 0;
  position: relative;
  z-index: 1;
  animation: glitch 5s infinite;
  text-shadow: 0 0 20px rgba(0, 255, 255, 0.8);
}

.fa {
  font-family: 'Another Danger', sans-serif;
  text-align: center;
  text-shadow:
    -2px -2px 0 #000,  
    2px -2px 0 #000,
    -2px 2px 0 #000,
    2px 2px 0 #000,
    0 0 15px rgba(0, 255, 255, 0.8);
  color: white;
  position: relative;
  animation: textFlicker 8s infinite;
}

.fa::before, .fa::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

.fa::before {
  color: #00ffff;
  animation: glitch 4s infinite;
  clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
  transform: translate(-3px);
  opacity: 0.8;
}

.fa::after {
  color: #ff00ff;
  animation: glitch 4s infinite;
  clip-path: polygon(0 60%, 100% 60%, 100% 100%, 0 100%);
  transform: translate(3px);
  opacity: 0.8;
}

.fb {
  font-family: 'Doctor Glitch', sans-serif;
  text-shadow: 1px 1px 1px #000;
  stroke: black;
  stroke-width: 2px;
  text-align: center;
  color: white;
}

/* Layout components */
.colbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: var(--z-index-content);
  gap: 1.5rem;
  padding: 1rem;
}

/* Reduced gap for the bg3 section containing the price */
.colbox.bg3 {
  gap: 0.25rem;
}

.rowbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: var(--z-index-content);
}

/* NFT Cards */
.nftbox, .nftboxS {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: right;
  padding: 1.75rem 1.75rem 2.25rem;
  margin: 0.75rem;
  border-radius: 15px;
  transition: transform 0.3s ease;
  animation: float 6s ease-in-out infinite;
  position: relative;
  overflow: hidden;
  z-index: var(--z-index-content);
  pointer-events: auto;
  min-width: 300px;
}

/* Separate styling for each type */
.nftbox {
  background-image: url('../styles/images/blueframe.gif') !important;
  box-shadow: 
    0 0 15px rgba(255, 0, 255, 0.3),
    inset 0 0 20px rgba(255, 0, 255, 0.2);
}

.nftboxS {
  background-image: url('../styles/images/pinkframe.gif') !important;
  box-shadow: 
    0 0 15px rgba(0, 255, 255, 0.3),
    inset 0 0 20px rgba(0, 255, 255, 0.2);
}

.nftbox:hover {
  transform: translateY(-5px);
  box-shadow: 
    0 5px 15px rgba(128, 0, 128, 0.3),
    0 10px 30px rgba(255, 0, 255, 0.2);
}

.nftboxS:hover {
  transform: translateY(-5px);
  box-shadow: 
    0 5px 15px rgba(0, 128, 128, 0.3),
    0 10px 30px rgba(0, 255, 255, 0.2);
}

/* Enhance the image container for 16:9 ratio */
.nft-animation-wrapper {
  position: relative;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  transition: all 0.3s ease;
  margin-bottom: 1rem;
  background-color: transparent;
}

/* Cyan shadow for staked NFTs */
.nftboxS .nft-animation-wrapper {
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.3);
}

/* Pink shadow for unstaked NFTs */
.nftbox .nft-animation-wrapper {
  box-shadow: 0 0 15px rgba(255, 0, 255, 0.3);
}

.nftmedia {
  border-radius: 15px !important;
  width: 100% !important;
  height: auto !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;
  position: relative;
  transform-origin: center;
  object-fit: contain !important;
  display: block !important;
  background-color: transparent;
}

.nftmedia:hover {
  transform: scale(1.05);
}

/* Simplify hover effects by removing pseudo-elements */
.nftmedia::before,
.nftmedia::after {
  display: none;
}

/* Buttons */
.stakebutton {
  background-image: url('../styles/images/pinkframe.gif') !important;
  background-size: cover !important;
  padding: 1.25% !important;
  border-radius: 12px !important;
  border: #000 !important;
  box-shadow: 0 0 6px #000 !important;
  transition: all 0.2s ease !important;
  position: relative !important;
  z-index: var(--z-index-buttons) !important;
  pointer-events: auto !important;
  cursor: pointer !important;
}

.withdrawbutton {
  background-image: url('../styles/images/blueframe.gif') !important;
  background-size: cover !important;
  padding: 1.25% !important;
  border-radius: 12px !important;
  border: #000 !important;
  box-shadow: 0 0 6px #000 !important;
  transition: all 0.2s ease !important;
  position: relative !important;
  z-index: var(--z-index-buttons) !important;
  pointer-events: auto !important;
  cursor: pointer !important;
}

.ccbutton {
  background-image: url('../styles/images/button2h.gif') !important;
  background-size: cover !important;
  padding: 0.75% !important;
  border-radius: 10px !important;
  background-position: bottom !important;
  border: #000 !important;
  box-shadow: 0 0 6px #000 !important;
  margin-top: 4vh !important;
  margin-bottom: 2vh !important;
  margin-left: 0.75vw !important;
  margin-right: 0.75vw !important;
  transition: all 0.2s ease !important;
  position: relative !important;
  z-index: var(--z-index-buttons) !important;
  pointer-events: auto !important;
  cursor: pointer !important;
}

.explorebutton {
  position: relative;
  background: linear-gradient(135deg, rgba(0, 32, 32, 0.8), rgba(0, 16, 16, 0.9));
  background-image: url('../styles/images/new/Button1.gif') !important;
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  border: 2px solid var(--primary-color);
  box-shadow: 0 0 15px var(--primary-color);
  border-radius: 8px;
  padding: 15px 30px;
  min-width: 250px;
  transition: all 0.1s ease;
  overflow: hidden;
  z-index: var(--z-index-buttons);
}

/* Create pseudo-elements for the glitch effect */
.explorebutton::before, 
.explorebutton::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  background-image: inherit;
  background-size: inherit;
  background-repeat: inherit;
  background-position: inherit;
  border-radius: inherit;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.explorebutton::before {
  filter: hue-rotate(90deg) contrast(1.5);
  transform: translateX(-5px);
}

.explorebutton::after {
  filter: hue-rotate(-90deg) contrast(1.5);
  transform: translateX(5px);
}

/* Hover animations */
.explorebutton:hover {
  animation: exploreButtonGlitch 1.5s steps(1) infinite;
  border-color: var(--secondary-color);
  transform: scale(1.03);
  transition: all 0.1s ease;
}

.explorebutton:hover::before,
.explorebutton:hover::after {
  opacity: 0.5;
  animation: exploreButtonSplitGlitch 1.5s steps(1) infinite;
}

.explorebutton:hover::before {
  animation-delay: 0.1s;
}

.explorebutton:hover::after {
  animation-delay: 0.2s;
}

/* Click/active state */
.explorebutton:active {
  transform: scale(0.97);
  box-shadow: 
    0 0 30px var(--secondary-color),
    0 0 15px var(--primary-color),
    0 0 5px var(--accent-color),
    inset 0 0 15px rgba(0, 255, 255, 0.5);
  filter: brightness(1.2) contrast(1.2);
  transition: all 0.05s ease;
}

.explorebutton:active::before,
.explorebutton:active::after {
  opacity: 0.8;
  filter: brightness(1.5) contrast(1.5);
}

/* Remove old animation code for the button */
.stakebutton:hover,
.withdrawbutton:hover,
.ccbutton:hover,
.custom-button:hover {
  transform: scale(1.02) translateY(-2px);
  animation: strobeGlitch 1s steps(1) infinite;
}

/* Ensure explorebutton doesn't inherit other animations */
.explorebutton:hover {
  animation: exploreButtonGlitch 1.5s steps(1) infinite;
}

/* Hide AURA circulation stats */
.aura-circ-stats {
  display: none !important;
}

.exploring {
  background: linear-gradient(135deg, rgba(32, 0, 32, 0.8), rgba(16, 0, 16, 0.9));
  border-color: var(--secondary-color);
  box-shadow: 0 0 15px var(--secondary-color);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.exploration-animation {
  width: 50px;
  height: 50px;
  border: 3px solid transparent;
  border-top-color: var(--primary-color);
  border-bottom-color: var(--secondary-color);
  border-radius: 50%;
  margin-bottom: 15px;
  animation: rotate 1.5s linear infinite;
}

.explore-button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.button-icon {
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.approve-mode {
  background: linear-gradient(135deg, rgba(32, 32, 0, 0.8), rgba(16, 16, 0, 0.9));
  border-color: #ffe100;
  box-shadow: 0 0 15px #ffe100;
}

.claim-mode {
  background: linear-gradient(135deg, rgba(0, 32, 0, 0.8), rgba(0, 16, 0, 0.9));
  border-color: #00ff00;
  box-shadow: 0 0 15px #00ff00;
}

.explore-mode {
  background: linear-gradient(135deg, rgba(0, 32, 32, 0.8), rgba(0, 16, 16, 0.9));
  border-color: var(--primary-color);
  box-shadow: 0 0 15px var(--primary-color);
}

/* Ensure ThirdWeb buttons are clickable */
[class*="web3button"],
[class*="connect"],
button[class*="web3"],
button[class*="connect"] {
  position: relative !important;
  z-index: var(--z-index-buttons) !important;
  pointer-events: auto !important;
  cursor: pointer !important;
}

/* Make sure button containers don't block clicks */
.nftbox,
.nftboxS,
.balance-row,
.bg2,
.bg3 {
  pointer-events: auto;
}

/* Fix explore button centering */
.rowbox.widthboost {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

/* Enhanced button hover effects with glitch */
.stakebutton,
.withdrawbutton,
.ccbutton,
.custom-button {
  position: relative !important;
  overflow: hidden !important;
  transition: all 0.2s ease !important;
}

.stakebutton:hover,
.withdrawbutton:hover,
.ccbutton:hover,
.custom-button:hover {
  transform: scale(1.02) translateY(-2px);
  animation: strobeGlitch 1s steps(1) infinite;
}

/* Fix rounded corners for overlays and containers */
.nftbox,
.nftboxS,
.bg2,
.bg3,
.balance-row,
.social-bar,
.emergency-claim-button,
.video-overlay,
.transaction-status-modal {
  border-radius: 12px;
  overflow: hidden;
}

.nftbox::before,
.nftboxS::before,
.bg2::before,
.bg3::before {
  content: '';
  position: absolute;
  inset: 0;
  background: inherit;
  border-radius: inherit;
  z-index: -1;
}

/* Enhanced glitch effect for text */
.glitch-text {
  position: relative;
  animation: textGlitch 3s infinite;
}

@keyframes textGlitch {
  0% {
    text-shadow: 
      0.05em 0 0 rgba(0, 255, 255, 0.75),
      -0.05em -0.025em 0 rgba(255, 0, 255, 0.75),
      -0.025em 0.05em 0 rgba(0, 255, 255, 0.75);
  }
  14% {
    text-shadow: 
      0.05em 0 0 rgba(0, 255, 255, 0.75),
      -0.05em -0.025em 0 rgba(255, 0, 255, 0.75),
      -0.025em 0.05em 0 rgba(0, 255, 255, 0.75);
  }
  15% {
    text-shadow: 
      -0.05em -0.025em 0 rgba(0, 255, 255, 0.75),
      0.025em 0.025em 0 rgba(255, 0, 255, 0.75),
      -0.05em -0.05em 0 rgba(0, 255, 255, 0.75);
  }
  49% {
    text-shadow: 
      -0.05em -0.025em 0 rgba(0, 255, 255, 0.75),
      0.025em 0.025em 0 rgba(255, 0, 255, 0.75),
      -0.05em -0.05em 0 rgba(0, 255, 255, 0.75);
  }
  50% {
    text-shadow: 
      0.025em 0.05em 0 rgba(0, 255, 255, 0.75),
      0.05em 0 0 rgba(255, 0, 255, 0.75),
      0 -0.05em 0 rgba(0, 255, 255, 0.75);
  }
  99% {
    text-shadow: 
      0.025em 0.05em 0 rgba(0, 255, 255, 0.75),
      0.05em 0 0 rgba(255, 0, 255, 0.75),
      0 -0.05em 0 rgba(0, 255, 255, 0.75);
  }
  100% {
    text-shadow: 
      -0.025em 0 0 rgba(0, 255, 255, 0.75),
      -0.025em -0.025em 0 rgba(255, 0, 255, 0.75),
      -0.025em -0.05em 0 rgba(0, 255, 255, 0.75);
  }
}

/* Balance displays */
.balance-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5vw;
  margin: 2vh 0;
  padding: 1vh 2vw;
  background-image: url('../styles/images/bg3.gif');
  background-position: center;
  background-size: cover;
  border-radius: 15px;
  box-shadow: 
    0 0 15px rgba(0, 255, 255, 0.3),
    inset 0 0 20px rgba(0, 255, 255, 0.2);
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(8px);
  z-index: 5;
  pointer-events: auto;
}

/* Add a wallet balance label */
.balance-label {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  background-image: url('../styles/images/button2h.gif') !important;
  background-size: cover !important;
  padding: 5px 15px !important;
  border-radius: 15px !important;
  font-family: 'Doctor Glitch', sans-serif !important;
  color: white !important;
  font-size: 0.9rem !important;
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.8) !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5) !important;
  z-index: 6 !important;
}

.balance-row::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent 0%,
    rgba(0, 255, 255, 0.2) 50%,
    transparent 100%
  );
  opacity: 0.8;
}

.balance-row::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    45deg,
    transparent 0%,
    rgba(0, 255, 255, 0.1) 50%,
    transparent 100%
  );
  animation: subtleParallax 4s infinite;
}

.balance-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.balance-item b {
  font-size: 1.25rem;
  color: var(--text-color);
  text-shadow: 
    0 0 10px rgba(255, 0, 255, 0.8),
    0 0 20px rgba(0, 128, 128, 0.4);
  position: relative;
  animation: textFlicker 6s infinite;
  font-family: 'Doctor Glitch', sans-serif !important; /* Use consistent font */
}

/* Background sections */
.bg1 {
  background-image: url('../styles/images/new/NBGM.gif');
  background-position: center center;
  background-size: cover; /* Changed to cover for better fit while preserving aspect ratio */
  background-repeat: no-repeat;
  position: relative;
  z-index: var(--z-index-content);
  min-height: 45vh; /* Increased for better display */
  display: flex;
  flex-direction: column;
  align-items: top;
  justify-content: top;
  padding-bottom: 30px;
  overflow: hidden;
}

/* Ensure background doesn't get too stretched on wide screens */
@media (min-width: 1200px) {
  .bg1 {
    background-size: 100% auto;
  }
}

/* Add new background for secondary section */
.bg-secondary {
  position: relative;
  z-index: var(--z-index-content);
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 20px;
  overflow: hidden;
}

/* Add rotated background as pseudo-element */
.bg-secondary::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../styles/images/bg1.gif');
  background-size: cover; /* Height adjusted to ensure coverage */
  background-position: center;
  transform-origin: center center;
  z-index: -1;
}

/* Keep content properly oriented */
.bg-secondary > * {
  transform: rotate(0deg);
  position: relative;
  z-index: 2;
}

/* Animated divider between sections */
.section-divider-animated {
  position: relative;
  height: 10px;
  width: 100%;
  margin: 0;
  overflow: hidden;
  z-index: var(--z-index-content);
  background-color: rgba(0, 0, 0, 0.5);
}

.section-divider-animated::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    var(--primary-color),
    var(--secondary-color),
    var(--primary-color),
    transparent
  );
  animation: dividerGlow 3s linear infinite;
}

.section-divider-animated::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(0, 255, 255, 0.8),
    transparent,
    rgba(255, 0, 255, 0.8),
    transparent
  );
  animation: dividerScan 4s ease-in-out infinite;
}

@keyframes dividerGlow {
  0% { opacity: 0.3; }
  50% { opacity: 0.8; }
  100% { opacity: 0.3; }
}

@keyframes dividerScan {
  0% { transform: translateX(0%); }
  100% { transform: translateX(50%); }
}

.bg2 {
  background-image: url('../styles/images/new/NBG.gif');
  border-radius: 15px;
  padding-bottom: 2%;
  background-position: center;
  background-size: cover;
  margin-left: 2.5%;
  margin-right: 2.5%;
  padding-left: 2%;
  padding-right: 2%;
  box-shadow: 0 0 6px #000;
  position: relative;
  z-index: var(--z-index-content);
  overflow: hidden;
  pointer-events: auto;
}

.bg3 {
  background-image: url('../styles/images/bg32.gif');
  border-radius: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 0 6px #000;
  margin-top: 1.5%;
  padding-left: 11.9%;
  padding-right: 11.9%;
  padding-bottom: 2.5rem;  /* Further reduced padding */
  padding-top: 1.5%;       /* Further reduced padding */
  position: relative;
  z-index: var(--z-index-content);
  overflow: visible;  /* Change to visible to allow button to show */
  pointer-events: auto;
}

/* Video overlay - highest z-index */
.video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(16px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--z-index-video);
}

.video-container {
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
  animation: subtleParallax 4s infinite;
  box-shadow: 
    0 0 30px rgba(0, 255, 255, 0.5),
    0 0 60px rgba(0, 255, 255, 0.3);
  border-radius: 25px; /* Match the prize-video border-radius */
  overflow: hidden; /* Ensure content stays within the rounded corners */
}

.prize-video {
  border-radius: 25px;
  box-shadow: 0 0 50px rgba(0, 255, 255, 0.3);
}

.prize-name {
  position: absolute;
  top: -40px;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 2rem;
  text-shadow: 
    0 0 20px rgba(0, 255, 255, 0.8),
    0 0 40px rgba(0, 255, 255, 0.4);
  animation: textFlicker 6s infinite;
}

/* Social bar - keep above everything */
.social-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-image: url('../styles/images/button3h.gif');
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.3);
  overflow-x: hidden;
  padding: 3px 5px;
  z-index: var(--z-index-social);
  backdrop-filter: blur(5px);
  height: calc(1.5rem + 6px); /* Reduced height */
}

.social-links-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 2px 0;
}

/* Social link styling */
.social-link {
  color: white;
  text-decoration: none;
  font-family: 'Doctor Glitch', sans-serif;
  font-size: 0.7rem; /* Smaller font size */
  transition: all 0.3s ease;
  text-shadow: 1px 1px 1px #000;
  position: relative;
  overflow: hidden;
  padding: 2px 5px;
}

.social-link:hover {
  transform: scale(1.1);
  animation: glitch 0.5s infinite;
  text-shadow: 
    0 0 10px rgba(0, 255, 255, 0.8),
    0 0 20px rgba(0, 255, 255, 0.4);
}

.social-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(0, 255, 255, 0.4),
    transparent
  );
  transition: 0.5s;
}

.social-link:hover::before {
  left: 100%;
}

.social-link::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, transparent, #00ffff, transparent);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.social-link:hover::after {
  transform: scaleX(1);
}

/* Developer credit styling */
.developer-credit {
  font-size: 0.6rem;
  color: rgba(255, 255, 255, 0.7);
  margin-left: 5px;
}

.developer-credit a {
  color: rgba(0, 255, 255, 0.8);
  text-decoration: none;
}

.developer-credit a:hover {
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.8);
}

/* Add volume control styles */
.volume-control-container {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 10;
}

.volume-button {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-image: url('../styles/images/button2h.gif');
  background-size: cover;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 6px #000;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  color: white;
}

.volume-button:hover {
  transform: scale(1.1);
  box-shadow: 
    0 0 10px rgba(0, 255, 255, 0.5),
    0 0 20px rgba(0, 255, 255, 0.3);
}

.volume-icon {
  font-size: 1.2rem;
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.8);
}

.volume-slider-container {
  position: absolute;
  bottom: 45px;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(0, 255, 255, 0.3);
  box-shadow: 
    0 0 10px rgba(0, 255, 255, 0.3),
    inset 0 0 10px rgba(0, 255, 255, 0.1);
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  backdrop-filter: blur(5px);
  animation: fadeIn 0.3s ease-in-out;
}

.volume-slider {
  width: 100px;
  height: 6px;
  -webkit-appearance: none;
  background: linear-gradient(to right, cyan, #005353);
  border-radius: 5px;
  outline: none;
}

.volume-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.8);
  cursor: pointer;
}

.volume-slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.8);
  cursor: pointer;
  border: none;
}

.volume-percentage {
  color: white;
  font-size: 0.8rem;
  font-family: 'Doctor Glitch', sans-serif;
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.8);
}

@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(10px); }
  100% { opacity: 1; transform: translateY(0); }
}

/* Emergency claim button */
.emergency-claim-container {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 20;
}

.emergency-claim-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: url('../styles/images/button2h.gif');
  background-size: cover;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 6px #000;
  transition: all 0.3s ease;
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.emergency-claim-label {
  display: none;
  align-items: center;
  gap: 5px;
  color: white;
  font-size: 0.9rem;
}

.emergency-claim-container:hover .emergency-claim-label {
  display: flex;
}

.emergency-claim-arrow {
  font-size: 1.2rem;
  animation: textFlicker 4s infinite;
}

/* Utility classes */
.topmar { margin-top: 1.5vh; }
.botmar { margin-bottom: 1.5vh; }
.padtop { padding-top: 2vh; }
.padbot { padding-bottom: 2vh; }

/* Smaller Gluttony's Indulgence section */
.gluttony-section {
  font-size: 1rem;
  margin-top: 5px;
  margin-bottom: 5px;
  line-height: 1.1;
  letter-spacing: -0.5px;
  transform: scale(0.95);
  padding: 0;
}

/* Smaller tooltip for Gluttony's section */
.gluttony-section .tooltip-text {
  font-size: 0.7rem;
  max-width: 200px;
  padding: 8px;
}

/* Section divider */
.section-divider {
  width: 90%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 10px auto;
}

/* Error message */
.error-message {
  color: red;
  margin: 10px 0;
  text-align: center;
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.2);
  padding: 10px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.error-message::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 0, 0, 0.2),
    transparent
  );
  animation: slide 2s infinite;
}

@keyframes slide {
  100% { left: 200%; }
}

/* Additional classes */
.widthboost { width: 100%; }
.widthboost2 { width: 100%; }

@media (max-width: 768px) {
  .widthboost { width: 100%; }
  .widthboost2 { width: 100%; }
  .main::after {
    background-size: 100% 2px;
  }
  
  .head {
    font-size: 2.5rem;
    animation: glitch 2s infinite;
  }

  .rowbox {
    flex-direction: column;
    gap: 1rem;
  }

  .social-bar {
    padding: 10px 0;
  }
  
  .social-link {
    padding: 5px 0;
  }
  
  .social-links-container {
    gap: 5px;
  }

  .nftbox, .nftboxS {
    max-width: 100%;
    margin: 0 1rem;
  }
}

/* Add new keyframes for subtle effects */
@keyframes textFlicker {
  0% { opacity: 1; text-shadow: 0 0 8px #ff00ff; }   /* Pink */
  25% { opacity: 0.9; text-shadow: 0 0 12px #008080; } /* Teal */
  50% { opacity: 1; text-shadow: 0 0 15px #00ffff; }  /* Cyan */
  75% { opacity: 0.9; text-shadow: 0 0 12px #ff00ff; } /* Pink */
  100% { opacity: 1; text-shadow: 0 0 8px #008080; }  /* Teal */
}

@keyframes borderGlow {
  0% { 
    box-shadow: 
      0 0 10px #00ffff,
      0 0 20px #00ffff,
      0 0 30px #00ffff,
      inset 0 0 10px #00ffff;
  }
  50% { 
    box-shadow: 
      0 0 15px #ff00ff,
      0 0 30px #ff00ff,
      0 0 45px #ff00ff,
      inset 0 0 20px #ff00ff;
  }
  100% { 
    box-shadow: 
      0 0 10px #00ffff,
      0 0 20px #00ffff,
      0 0 30px #00ffff,
      inset 0 0 10px #00ffff;
  }
}

@keyframes subtleParallax {
  0% { transform: translate(0, 0); }
  25% { transform: translate(-4px, 4px); }
  50% { transform: translate(4px, -4px); }
  75% { transform: translate(-4px, -4px); }
  100% { transform: translate(0, 0); }
}

/* Enhance NFT cards */
.nftbox::after, .nftboxS::after {
  display: none;
}

/* Add glow to buttons on hover */
.stakebutton:hover,
.withdrawbutton:hover,
.ccbutton:hover,
.custom-button:hover {
  transform: scale(1.05);
  animation: borderGlow 1.5s infinite;
}

/* Enhance balance display */
.balance-item b {
  position: relative;
  animation: textFlicker 6s infinite;
}

/* Add depth to background sections */
.bg2::after, .bg3::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    135deg,
    transparent,
    rgba(0, 255, 255, 0.1),
    transparent
  );
  animation: subtleParallax 6s infinite;
}

/* Emergency button enhancement */
.emergency-claim-button {
  position: relative;
  overflow: hidden;
}

.emergency-claim-button::before {
  content: '';
  position: absolute;
  inset: -2px;
  background: linear-gradient(45deg, #ff0000, #ff00ff);
  opacity: 0;
  transition: opacity 0.3s;
}

.emergency-claim-button:hover::before {
  opacity: 0.5;
  animation: borderGlow 1s infinite;
}

/* Add subtle parallax to video overlay */
.video-container {
  animation: subtleParallax 4s infinite;
}

/* Error message enhancement */
.error-message {
  position: relative;
  overflow: hidden;
}

.error-message::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 0, 0, 0.2),
    transparent
  );
  animation: slide 2s infinite;
}

@keyframes slide {
  100% { left: 200%; }
}

/* Add rotation animation */
@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Move scanline effect to a separate element */
.scanline-overlay {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    to bottom,
    transparent 50%,
    rgba(0, 255, 255, 0.05) 50%  /* Cyan */
  );
  background-size: 100% 4px;
  pointer-events: none !important;
  animation: scanline 8s linear infinite;
  z-index: var(--z-index-scanline);
  opacity: 0.7;
}

/* Add cyberpunk border effect to inputs */
.cyber-input {
  border: 2px solid rgba(0, 255, 255, 0.3);
  background: rgba(0, 0, 0, 0.7);
  color: #00ffff;
  border-radius: 8px;
  padding: 8px 12px;
  transition: all 0.3s ease;
}

.cyber-input:focus {
  border-color: #ff00ff;
  box-shadow: 
    0 0 10px rgba(0, 255, 255, 0.3),
    0 0 20px rgba(255, 0, 255, 0.2);
  outline: none;
}

@keyframes strobeGlitch {
  0%, 100% {
    clip-path: inset(0);
    transform: translate(0);
    filter: brightness(1) saturate(1);
  }
  10% {
    clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
    transform: translate(-2px);
    filter: brightness(1.2) saturate(1.2);
    border: 2px solid #ff00ff;  /* Pink */
  }
  20% {
    clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 90%);
    transform: translate(2px);
    filter: brightness(1.1) saturate(1.3);
    border: 2px solid #008080;  /* Teal */
  }
  30% {
    clip-path: polygon(5% 15%, 95% 5%, 95% 85%, 5% 95%);
    transform: translate(1px);
    filter: brightness(1.2) saturate(1.1);
    border: 2px solid #00ffff;  /* Cyan */
  }
  40% {
    clip-path: polygon(5% 5%, 95% 15%, 95% 95%, 5% 85%);
    transform: translate(-1px);
    filter: brightness(1.1) saturate(1.2);
    border: 2px solid #ff00ff;  /* Pink */
  }
  50% {
    clip-path: inset(0);
    transform: translate(0);
    filter: brightness(1) saturate(1);
  }
  60% {
    clip-path: polygon(0 15%, 100% 5%, 100% 85%, 0 95%);
    transform: translate(2px);
    filter: brightness(1.2) saturate(1.3);
    border: 2px solid #008080;  /* Teal */
  }
  70% {
    clip-path: polygon(0 5%, 100% 15%, 100% 95%, 0 85%);
    transform: translate(-2px);
    filter: brightness(1.1) saturate(1.2);
    border: 2px solid #00ffff;  /* Cyan */
  }
  80% {
    clip-path: polygon(0 15%, 100% 5%, 100% 85%, 0 95%);
    transform: translate(1px);
    filter: brightness(1.2) saturate(1.1);
    border: 2px solid #ff00ff;  /* Pink */
  }
  90% {
    clip-path: polygon(0 5%, 100% 15%, 100% 95%, 0 85%);
    transform: translate(-1px);
    filter: brightness(1.1) saturate(1.3);
    border: 2px solid #008080;  /* Teal */
  }
}

@keyframes exploreButtonSplitGlitch {
  0%, 100% {
    clip-path: inset(0);
  }
  10% {
    clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
  }
  20% {
    clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 90%);
  }
  30% {
    clip-path: polygon(5% 15%, 95% 5%, 95% 85%, 5% 95%);
  }
  40% {
    clip-path: polygon(5% 5%, 95% 15%, 95% 95%, 5% 85%);
  }
  50% {
    clip-path: inset(0);
  }
  60% {
    clip-path: polygon(0 15%, 100% 5%, 100% 85%, 0 95%);
  }
  70% {
    clip-path: polygon(0 5%, 100% 15%, 100% 95%, 0 85%);
  }
  80% {
    clip-path: inset(10% 5% 10% 5%);
  }
  90% {
    clip-path: inset(5% 10% 5% 10%);
  }
}

/* NFT Grid for better mobile responsiveness */
.nft-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 2rem;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  z-index: var(--z-index-content);
}

@media (max-width: 768px) {
  .nft-grid {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1.5rem;
  }
}

/* Loading indicator overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
}

.loading-spinner {
  width: 70px;
  height: 70px;
  border: 4px solid transparent;
  border-radius: 50%;
  border-top-color: var(--primary-color);
  border-bottom-color: var(--secondary-color);
  animation: rotate 2s linear infinite;
  position: relative;
  margin-bottom: 20px;
}

.spinner-inner {
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  border: 3px solid transparent;
  border-radius: 50%;
  border-left-color: var(--primary-color);
  border-right-color: var(--secondary-color);
  animation: rotate 1.5s linear infinite reverse;
}

.loading-text {
  font-size: 1.5rem;
  margin-top: 1rem;
  color: var(--primary-color);
  text-shadow: 0 0 10px var(--primary-color);
  animation: textFlicker 2s infinite alternate;
}

/* Emergency claim improvements */
.emergency-claim-container {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 20;
}

.emergency-claim-hint {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.6);
  display: block;
  margin-top: 0.25rem;
}

.emergency-claim-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Loading text styles */
.loading-text {
  font-size: 1.5rem;
  margin-top: 1rem;
  color: var(--primary-color);
  text-shadow: 0 0 10px var(--primary-color);
  animation: textFlicker 2s infinite alternate;
}

/* Progress bar styles */
.progress-container {
  width: 300px;
  margin: 1rem auto;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 900;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid var(--primary-color);
  box-shadow: 0 0 15px var(--primary-color);
}

.progress-label {
  margin-bottom: 5px;
  font-size: 1rem;
  color: var(--primary-color);
}

.progress-track {
  height: 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.progress-fill {
  height: 100%;
  background: linear-gradient(90deg, var(--primary-color), var(--secondary-color));
  border-radius: 10px;
  transition: width 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.7);
}

.progress-text {
  margin-top: 5px;
  font-size: 0.8rem;
  text-align: right;
  color: white;
}

/* Token cards enhanced styling */
.token-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 15px;
  padding: 15px;
  min-width: 120px;
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.3);
  border: 1px solid var(--primary-color);
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.token-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 20px var(--primary-color);
}

.token-card::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: conic-gradient(
    transparent, 
    rgba(0, 255, 255, 0.3), 
    transparent, 
    transparent
  );
  animation: rotate 4s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.token-card:hover::before {
  opacity: 1;
}

.token-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.token-icon img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.token-amount {
  font-size: 1.2rem;
  margin-bottom: 5px;
  background: linear-gradient(90deg, var(--primary-color), var(--secondary-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
  font-family: 'Doctor Glitch', sans-serif !important; /* Use consistent font */
}

.token-symbol {
  font-size: 0.9rem;
  color: #fff;
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.7);
}

/* Media queries for mobile responsiveness */
@media (max-width: 768px) {
  .token-card {
    min-width: 100px;
    padding: 10px;
  }
  
  .token-amount {
    font-size: 1rem;
  }
  
  .token-symbol {
    font-size: 0.8rem;
  }
  
  .balance-row {
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }
}

/* Notification system */
.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  min-width: 300px;
  max-width: 450px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  animation: slideIn 0.5s ease forwards;
  transform: translateX(120%);
}

@keyframes slideIn {
  to { transform: translateX(0); }
}

.success-notification {
  background: linear-gradient(135deg, rgba(0, 50, 0, 0.8), rgba(0, 30, 0, 0.9));
  border: 1px solid #00ff00;
  box-shadow: 0 0 15px rgba(0, 255, 0, 0.5);
}

.error-notification {
  background: linear-gradient(135deg, rgba(50, 0, 0, 0.8), rgba(30, 0, 0, 0.9));
  border: 1px solid #ff0000;
  box-shadow: 0 0 15px rgba(255, 0, 0, 0.5);
}

.info-notification {
  background: linear-gradient(135deg, rgba(0, 0, 50, 0.8), rgba(0, 0, 30, 0.9));
  border: 1px solid #0088ff;
  box-shadow: 0 0 15px rgba(0, 136, 255, 0.5);
}

.notification-content {
  display: flex;
  align-items: center;
  gap: 15px;
  flex: 1;
}

.notification-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.success-icon::before {
  content: '✓';
  color: #00ff00;
}

.error-icon::before {
  content: '✗';
  color: #ff0000;
}

.info-icon::before {
  content: 'ℹ';
  color: #0088ff;
}

.notification-message {
  font-family: 'Doctor Glitch', sans-serif;
  color: white;
  font-size: 0.9rem;
  flex: 1;
}

.notification-close {
  background: transparent;
  border: none;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;
  margin-left: 10px;
}

.notification-close:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .notification {
    min-width: auto;
    left: 20px;
    right: 20px;
    max-width: calc(100% - 40px);
  }
}

/* NFT animation and status indicators */
.nft-animation-wrapper {
  position: relative;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  transition: all 0.3s ease;
  margin-bottom: 1rem;
  background-color: transparent;
}

.nft-glow-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  z-index: 2;
  border-radius: 15px;
}

/* Cyan glow for staked NFTs */
.nftboxS .nft-glow-overlay {
  background: radial-gradient(circle at center, transparent 30%, rgba(0, 255, 255, 0.5) 100%);
}

/* Pink glow for unstaked NFTs */
.nftbox .nft-glow-overlay {
  background: radial-gradient(circle at center, transparent 30%, rgba(255, 0, 255, 0.5) 100%);
}

.nftboxS:hover .nft-glow-overlay,
.nftbox:hover .nft-glow-overlay {
  opacity: 0.8;
}

.stake-status {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 8px 0;
  padding: 6px 12px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  backdrop-filter: blur(5px);
}

.status-indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
}

.staked {
  background: #ff00ff;
  box-shadow: 0 0 10px #ff00ff;
  animation: pulse 2s infinite;
}

.unstaked {
  background: #ffcc00;
  box-shadow: 0 0 10px #ffcc00;
}

.status-text {
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Tooltip styles */
.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: help;
}

.tooltip-text {
  visibility: hidden;
  width: 240px;
  background: rgba(0, 0, 0, 0.8);
  color: #00ffff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #00ffff;
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
  
  /* Position the tooltip */
  position: absolute;
  z-index: 999;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  
  /* Add animation */
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
  
  /* Initial state */
  transform: translateX(-50%) translateY(10px);
  
  /* Text styling */
  font-family: 'Doctor Glitch', sans-serif;
  font-size: 0.8rem;
  font-weight: normal;
  letter-spacing: 0.5px;
  
  /* Prevent line breaks */
  white-space: normal;
}

/* Triangle pointer */
.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: #00ffff transparent transparent transparent;
}

/* Show tooltip on hover */
.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
  transform: translateX(-50%) translateY(0);
}

/* Add scan lines to tooltip */
.tooltip-text::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    transparent 50%,
    rgba(0, 255, 255, 0.05) 50%
  );
  background-size: 100% 4px;
  pointer-events: none;
  opacity: 0.5;
  z-index: -1;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .tooltip-text {
    width: 200px;
    font-size: 0.7rem;
    padding: 8px;
  }
}

/* Reward animation styles */
.reward-animation-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  pointer-events: none;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.25);
}

.reward-amount {
  font-family: 'Another Danger', sans-serif;
  font-size: 4rem;
  color: #00ffff;
  text-shadow: 
    0 0 10px #00ffff,
    0 0 15px #00ffff;
  animation: rewardPulse 1.2s infinite alternate, rewardFloat 3s ease-in-out;
  margin-bottom: 2rem;
  z-index: 2;
}

.particles-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.particle {
  position: absolute;
  background: radial-gradient(circle, rgba(0, 255, 255, 0.8) 20%, transparent 70%);
  border-radius: 50%;
  animation: particleRise 3s ease-out;
  opacity: 0;
}

@keyframes rewardPulse {
  0% {
    transform: scale(1);
    color: #00ffff;
    text-shadow: 
      0 0 10px rgba(0, 255, 255, 0.7),
      0 0 15px rgba(0, 255, 255, 0.5);
  }
  100% {
    transform: scale(1.05);
    color: #80ffff;
    text-shadow: 
      0 0 12px rgba(0, 255, 255, 0.9),
      0 0 18px rgba(0, 255, 255, 0.7);
  }
}

@keyframes rewardFloat {
  0% { transform: translateY(20px); opacity: 0; }
  15% { opacity: 1; }
  85% { opacity: 1; }
  100% { transform: translateY(-20px); opacity: 0; }
}

@keyframes particleRise {
  0% { 
    transform: translateY(0) scale(1); 
    opacity: 0.6;
  }
  100% { 
    transform: translateY(-400px) scale(0.2); 
    opacity: 0;
  }
}

/* NFT loading and fallback styles */
.nft-loading-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
}

/* Styling for the YOUR HOLDINGS label */
.holdings-label {
  text-align: center;
  font-size: 0.9rem;
  color: #00ffff;
  margin-top: 5px;
  margin-bottom: 2px;
  opacity: 0.9;
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.nft-spinner {
  width: 60px;
  height: 60px;
  margin-bottom: 1rem;
  border: 5px solid rgba(0, 200, 200, 0.1);
  border-top: 5px solid rgba(0, 255, 255, 0.8);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.5);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.nft-fallback-text {
  font-family: 'Doctor Glitch', sans-serif;
  font-size: 1.2rem;
  color: white;
  text-shadow: 0 0 10px var(--primary-color);
  animation: textFlicker 2s infinite alternate;
}

.nft-loading-placeholder::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: repeating-linear-gradient(
    45deg,
    rgba(0, 255, 255, 0.1),
    rgba(0, 255, 255, 0.1) 10px,
    rgba(255, 0, 255, 0.1) 10px,
    rgba(255, 0, 255, 0.1) 20px
  );
  opacity: 0.3;
  animation: scanline 10s linear infinite;
}

/* Large NFT display for 1-2 NFTs */
.large-nft-display {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 3rem;
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  padding: 1rem 0;
}

.large-card {
  max-width: 800px;
  width: 100%;
  transition: transform 0.3s ease;
}

.large-card:hover {
  transform: translateY(-5px);
}

.large-card .nft-animation-wrapper {
  width: 100%;
  max-height: none;
  margin-bottom: 1.5rem;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 25px rgba(0, 255, 255, 0.4);
  background-color: transparent;
}

.large-card .nftmedia {
  border-radius: 15px !important;
  width: 100% !important;
  height: auto !important;
  object-fit: contain !important;
  background-color: transparent;
}

/* Mobile responsiveness for large NFT display */
@media (max-width: 1200px) {
  .large-nft-display {
    flex-direction: column;
    align-items: center;
  }
  
  .large-card {
    max-width: 90vw;
    margin-bottom: 2rem;
  }
}

/* Text spacing in cards */
.nftbox h2, .nftboxS h2,
.nftbox h4, .nftboxS h4 {
  margin: 0.5rem 0;
}

/* Compact token display */
.compact-token-display {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
  margin-top: 0.25rem;
  padding: 0.5rem;
  width: 100%;
}

.compact-token {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  background: rgba(0, 0, 0, 0.3);
  padding: 0.5rem 0.8rem;
  border-radius: 15px;
  border: 1px solid rgba(0, 255, 255, 0.2);
  box-shadow: 0 0 5px rgba(0, 255, 255, 0.2);
  min-width: 120px;
  min-height: 60px;
  justify-content: center;
}

.mini-token-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.mini-token-amount {
  font-size: 0.9rem;
  color: white;
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
  text-align: center;
  font-family: 'Doctor Glitch', sans-serif !important; /* Use consistent font */
}

@media (max-width: 768px) {
  .compact-token-display {
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .compact-token {
    width: 80%;
    justify-content: center;
  }
}

/* Fix for the gap when no unstaked NFTs are found */
.no-unstaked-container {
  margin-top: 0;
  padding-top: 0;
}

.no-unstaked-message {
  margin-bottom: 20px;
}

/* When no unstaked NFTs but staked NFTs exist, adjust the spacing */
.staked-only-view .colbox {
  gap: 0;
}

.staked-only-view .topmar {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0.5vh !important;
}

.staked-only-view .padtop {
  padding-top: 0 !important;
}

.staked-only-view h2.fa.topmar {
  margin-top: 0.5vh !important;
  margin-bottom: 0 !important;
  font-size: 1.75rem !important;
}

.staked-only-view .section-divider {
  display: none !important;
}

.staked-only-view .no-unstaked-message {
  margin: 0 !important;
  font-size: 0.8rem !important;
  opacity: 0.7;
}

.staked-only-view .rowboxGAP {
  margin: 0 !important;
  padding: 0 !important;
}

.staked-only-view .rmar {
  margin: 0 !important;
}

/* Title for staked-only view */
.staked-only-title {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0.75vh !important;
  padding-bottom: 0 !important;
  font-size: 2rem !important;
  color: var(--primary-color) !important;
  text-shadow: 0 0 15px var(--primary-color) !important;
}

/* Container for staked NFTs in staked-only view */
.staked-only-container {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

/* Adjust NFT display in staked-only view */
.staked-only-view .large-nft-display {
  padding: 0 !important;
  margin-top: 0.5vh !important;
}

.staked-only-view .nft-grid {
  gap: 1rem !important;
  padding: 0 !important;
  margin-top: 0.5vh !important;
}

/* Reduce padding in parent container for staked-only view */
.staked-only-view.colbox.bg2 {
  padding-top: 0 !important;
  padding-bottom: 1vh !important;
  padding-left: 1.5% !important;
  padding-right: 1.5% !important;
}

/* No bottom margin class */
.no-botmar {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

/* Collapse empty containers when no content */
.empty-container {
  display: none;
}

/* Make claim rewards area more compact in staked-only view */
.staked-only-view .claim-pending-display {
  margin-bottom: 0.5vh !important;
  margin-top: 0.5vh !important;
}

.staked-only-view .center-column {
  gap: 0.25vh !important;
  margin-top: 0.5vh !important;
}

.staked-only-view .ccbutton {
  margin-top: 0.25vh !important;
  margin-bottom: 0.5vh !important;
  transform: scale(0.95);
}

/* Reduce spacing between cards in staked-only view */
.staked-only-view .nftboxS {
  padding-bottom: 1.25rem !important;
  padding-top: 1.25rem !important;
  margin: 0.5rem !important;
}

/* Unstaked only view - no staked NFTs */
.unstaked-only-view h2.fa.topmar {
  margin-top: 0.5vh !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  font-size: 2rem !important;
}

.unstaked-only-view .colbox {
  gap: 0 !important;
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.unstaked-only-view .rowboxGAP {
  margin: 0 !important;
  padding: 0 !important;
}

.unstaked-only-view .rmar {
  margin: 0 !important;
}

/* Adjust NFT display in unstaked-only view */
.unstaked-only-view .large-nft-display {
  padding-top: 0 !important;
  margin-top: 0.5vh !important;
}

.unstaked-only-view .nft-grid {
  gap: 1rem !important;
  padding-top: 0 !important;
  margin-top: 0.5vh !important;
}

/* Reduce padding in parent container for unstaked-only view */
.unstaked-only-view.colbox.bg2 {
  padding-top: 0 !important;
  padding-bottom: 1vh !important;
  padding-left: 1.5% !important;
  padding-right: 1.5% !important;
}

/* Reduce spacing between cards in unstaked-only view */
.unstaked-only-view .nftbox {
  padding-bottom: 1.25rem !important;
  padding-top: 1.25rem !important;
  margin: 0.5rem !important;
}

.white-text {
  color: white !important;
}